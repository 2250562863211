<template>
    <div class="app-container">
        <el-page-header v-if="isShowBack === '1'" @back="goBack" content="退款申请页面"/>
        <el-form :model="queryParams" :rules="searchRules" ref="queryForm" :inline="true" label-width="68px">

            <el-form-item label="进度状态" prop="queryType">
                <el-select v-model="queryParams.queryType" size="mini" @change="handleQuery">
                    <el-option v-for="item in queryTypeList" :key="item.value" :value="item.value"
                               :label="item.label"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="状态筛选" prop="status">
                <el-select v-model="queryParams.status" size="mini" :clearable="true">
                    <el-option v-for="(item,index) in refundStatusList" :key="index" :value="item.value"
                               :label="item.label"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="退款编号" prop="refundNo">
                <el-input v-model.trim="queryParams.refundNo" placeholder="请输入退款编号" clearable size="mini"/>
            </el-form-item>

            <el-form-item label="提交人" prop="submitId">
                <el-select v-model="queryParams.submitId" placeholder="选择提交人" filterable clearable size="small">
                    <el-option
                            v-for="ad in adviserList"
                            :value="ad.id"
                            :key="ad.id"
                            :label="ad.nickName"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="手机号" prop="phone">
                <el-input v-model.trim="queryParams.phone" placeholder="请输入手机号" clearable size="mini"/>
            </el-form-item>

            <el-form-item label="机会ID" prop="opportunityId">
                <el-input v-model.trim="queryParams.opportunityId" placeholder="请输入机会ID" clearable size="mini"
                          type="number"/>
            </el-form-item>

            <el-form-item label="订单编号" prop="orderNumber">
                <el-input v-model="queryParams.orderNumber" placeholder="订单编号" clearable size="mini"/>
            </el-form-item>

            <el-form-item label="商品名称" prop="goodsName">
                <el-input v-model="queryParams.goodsName" placeholder="请输入商品名称" clearable size="mini"/>
            </el-form-item>

            <el-form-item label="提交日期" prop="commitDateArr">
                <el-date-picker
                        v-model="commitDateArr"
                        clearable
                        size="mini"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="退款日期" prop="RefundDateArr">
                <el-date-picker
                        v-model="refundDateArr"
                        clearable
                        size="mini"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-button type="success" size="mini" @click="changeDetail(true)">添加</el-button>
            <el-button v-permission="'crm:refund:export'" type="primary" size="mini" @click="exportRefund">导出
            </el-button>
        </el-row>

        <el-table max-height="600" v-loading="loading" :data="refundList">
            <el-table-column label="退款编号" align="center" prop="refundNo" width="250"/>
            <el-table-column label="订单编号" align="center" prop="orderNumber" width="250"/>
            <el-table-column label="机会ID" align="center" prop="opportunityId" width="100"/>
            <el-table-column label="手机号" align="center" prop="phone" width="110"/>
            <el-table-column label="商品名称" align="center" prop="goodsName" width="200"/>
            <el-table-column label="学习顾问" align="center" prop="adviserId" width="80">
                <template v-slot="scope">
                    <el-tag effect="plain" :disable-transitions="true" v-for="item in adviserList" :key="item.id"
                            v-if="scope.row.adviserId === item.id">
                        {{ item.nickName }}
                    </el-tag>
                </template>
            </el-table-column>

            <el-table-column label="提交人" align="center" prop="submitId" width="100" :key="8">
                <template v-slot="scope">
                    <el-tag :disable-transitions="true" effect="dark" v-for="item in adviserList" :key="item.id"
                            v-if="scope.row.submitId === item.id">
                        {{ item.nickName }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="提交时间" align="center" prop="commitTime" width="150" :sortable="true"/>
            <el-table-column label="退款时间" align="center" prop="secondSuggestLastTime" width="150"/>

            <el-table-column label="原价" align="center" prop="payPrice" width="100">
                <template v-slot="scope">
                    {{ moneyFormat(scope.row.payPrice / 100) }}
                </template>
            </el-table-column>

            <el-table-column label="退款金额" align="center" prop="refundPrice" width="100">
                <template v-slot="scope">
                    {{ moneyFormat(scope.row.refundPrice / 100) }}
                </template>
            </el-table-column>

            <el-table-column label="订单渠道" align="center" prop="orderChannel">
                <template v-slot="scope">
                    <el-tag :disable-transitions="true" type="warning" v-for="item in orderChannelList" :key="item.id"
                            v-if="scope.row.orderChannel === item.dictValue">
                        {{ item.dictLabel }}
                    </el-tag>
                </template>
            </el-table-column>

            <el-table-column label="创建时间" align="center" prop="createTime" type="date" width="100"/>

            <el-table-column label="状态" align="center" prop="status" width="100" :key="10">
                <template v-slot="scope">
                    <el-tag :disable-transitions="true" v-for="item in refundStatusList" :key="item.value"
                            :type="item.type"
                            effect="dark"
                            v-if="scope.row.status === item.value">
                        {{ item.label }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="一审通过人" align="center" prop="firstSuggestLastBy" width="150"/>
            <el-table-column label="一审通过时间" align="center" prop="firstSuggestLastTime" width="150"/>
            <el-table-column label="二审通过人" align="center" prop="secondSuggestLastBy" width="150"/>
            <el-table-column label="二审通过时间" align="center" prop="secondSuggestLastTime" width="150"/>
            <el-table-column label="操作" fixed="right" width="200" align="center" class-name="small-padding">
                <template v-slot="scope">
                    <el-button type="primary" size="mini" @click="refundInfo(scope.row.id)">
                        查看详情
                    </el-button>

                    <el-popconfirm
                            v-if="scope.row.status === 0 || scope.row.status === 3"
                            confirm-button-text='好的'
                            cancel-button-text='不用了'
                            icon="el-icon-info"
                            icon-color="red"
                            title="确认删除本条数据吗？"
                            @confirm="deleteOrderRepair(scope.row.id)">
                        <el-button v-permission="'crm:refund:delete'" type="danger" size="mini"
                                   slot="reference">
                            删除
                        </el-button>
                    </el-popconfirm>

                    <el-button v-permission="'crm:refund:toOrderManagement'" type="success" size="mini"
                               @click="toOrderManagement(scope.row.id)"
                               v-if="scope.row.status === 9">
                        同步
                    </el-button>
                    <el-tooltip v-permission="'crm:refund:first'"
                                content="审核可进行驳回,通过.驳回可以让学习顾问重新修改"
                                placement="left"
                                v-if="scope.row.status === 1">
                        <el-button type="success" size="mini" @click="examineRepair(scope.row.id, 1)">
                            审核
                        </el-button>
                    </el-tooltip>
                    <el-tooltip v-permission="'crm:refund:second'"
                                content="审核可进行驳回,通过.驳回可以让学习顾问重新修改" placement="left"
                                v-if="scope.row.status === 2">
                        <el-button type="success" size="mini" @click="examineRepair(scope.row.id, 2)">
                            审核
                        </el-button>
                    </el-tooltip>
                    <el-tooltip content="撤回后,订单可进行修改重新提交" placement="left"
                                v-if="(scope.row.status === 1 || scope.row.status === 2)">
                        <el-button type="warning" size="mini" @click="backRepair(scope.row.id)">
                            撤回
                        </el-button>
                    </el-tooltip>
                    <el-tooltip content="订单在未提交前可进行修改" placement="left"
                                v-if="scope.row.status === 0 || scope.row.status === 3">
                        <el-button type="error" size="mini" @click="updateRepair(scope.row.id)">
                            修改
                        </el-button>
                    </el-tooltip>
                    <el-tooltip content="订单日志" placement="left"
                                v-permission="'crm:refund:log'">
                        <el-button type="info" plain size="mini" @click="queryLog(scope.row.id)">
                            操作日志
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>

        <pagination
                :v-show="queryParams.total > 10"
                :total="queryParams.total"
                :page.sync="queryParams.current"
                :limit.sync="queryParams.size"
                @pagination="handleQuery"/>

        <RefundRepairDetail
                v-if="detailDialogVisible.show"
                :adviser-list="adviserList"
                :refund-id="detailDialogVisible.id"
                :examine-type="detailDialogVisible.examineType"
                :open-refund-detail="detailDialogVisible.show"
                :refund-status="detailDialogVisible.status"
                @changeDetail="changeDetail()"
                @handleQuery="handleQuery"/>

        <el-dialog title="退款日志" :visible.sync="refundLogDialogVisible">
            <el-table :data="logList">
                <el-table-column property="refundNo" label="退款申请编号" width="180"/>
                <el-table-column property="orderNumber" label="订单编号" width="200"/>
                <el-table-column property="phone" label="手机号"/>
                <el-table-column property="opportunityId" label="机会ID"/>
                <el-table-column property="operateTypeDes" label="操作类型">
                    <template v-slot="scope">
                        <el-button type="text">{{ scope.row.operateTypeDes }}</el-button>
                    </template>
                </el-table-column>
                <el-table-column property="beforeStatusDes" label="操作前状态"/>
                <el-table-column property="afterStatusDes" label="操作后状态"/>
                <el-table-column property="suggestType" label="审核类型">
                    <template v-slot="scope">
                        <el-tag v-if="scope.row.suggestType === 1">一审</el-tag>
                        <el-tag type="success" v-else-if="scope.row.suggestType === 2">二审</el-tag>
                    </template>
                </el-table-column>
                <el-table-column property="suggestText" label="审核意见"></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import RefundRepairDetail from "@/components/order/RefundRepairDetail.vue";
import * as refundApi from "@/api/crm/refundRepair"
import * as  accountApi from "@/api/system/account";

export default {
    name: "RefundRepair",
    components: {
        RefundRepairDetail
    },
    data() {
        return {
            refundList: [],
            loading: false,
            isShowBack: 0,
            queryParams: {
                queryType: 0,
                status: '',
                submitId: '',
                refundNo: '',
                phone: '',
                opportunityId: '',
                orderNumber: '',
                goodsName: '',
                deptId: '',
                total: 0,
                current: 1,
                size: 10,
                startDateTime: null,
                endDateTime: null,
            },
            refundStatusList: [],
            queryTypeList: [
                {value: 0, label: "我的退款"},
                {value: 1, label: "审批录单"},
                {value: 2, label: "审核记录"},
                {value: 3, label: "全部"}
            ],
            searchRules: {},
            adviserList: [],
            refundDateArr: [],
            commitDateArr: [],
            collectionWayList: [],
            orderChannelList: [],
            payAccountArr: [],
            detailDialogVisible: {
                show: false,
                id: null,
                refundId: null,
                examineType: null,
                status: null
            },
            logList: [],
            refundLogDialogVisible: false,
        }
    },
    methods: {
        //金额格式化
        moneyFormat(number) {
            number = (number + '').replace(/[^0-9+-Ee.]/g, '');
            var n = !isFinite(+number) ? 0 : +number,
                    prec = !isFinite(+2) ? 0 : Math.abs(2),
                    s = '',
                    toFixedFix = function (n, prec) {
                        var k = Math.pow(10, prec);
                        return '' + Math.floor(n * k) / k;
                    };
            s = (prec ? toFixedFix(n, prec) : '' + Math.floor(n)).split('.');
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }
            return s.join('.');
        },
        goBack() {
            //跳转到上一次浏览的页面
            this.$router.go(-1)
        },
        handleQuery() {
            if (this.refundDateArr) {
                this.queryParams.refundStartDate = this.refundDateArr[0];
                this.queryParams.refundEndDate = this.refundDateArr[1];
            } else {
                this.queryParams.refundStartDate = null;
                this.queryParams.refundEndDate = null;
            }
            if (this.commitDateArr) {
                this.queryParams.commitStartDate = this.commitDateArr[0];
                this.queryParams.commitEndDate = this.commitDateArr[1];
            } else {
                this.queryParams.commitStartDate = null;
                this.queryParams.commitEndDate = null;
            }
            refundApi.listPage(this.queryParams).then(res => {
                this.refundList = res.data.data;
                this.queryParams.total = res.data.total;
                this.queryParams.current = res.data.current;
                this.queryParams.size = res.data.size;
            })
        },
        resetQuery(formName) {
            this.$refs[formName].resetFields();
            this.refundDateArr = []
            this.commitDateArr = []
            this.handleQuery();
        },
        refundInfo(id) {
            this.detailDialogVisible.id = id;
            this.detailDialogVisible.status = 3;
            this.detailDialogVisible.show = true
        },
        deleteOrderRepair(id) {
            refundApi.deleteById(id).then(res => {
                if (res.success) {
                    this.$message.success(res.msg)
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        toOrderManagement(id) {
            refundApi.handleSync(id).then(res => {
                if (res.success) {
                    this.$message.success(res.msg)
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        examineRepair(id, status) {
            this.detailDialogVisible.id = id;
            this.detailDialogVisible.status = status;
            this.detailDialogVisible.show = true
        },
        backRepair(id) {
            refundApi.backRepair(id).then(res => {
                if (res.success) {
                    this.$message.success(res.msg)
                    this.handleQuery();
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        updateRepair(id) {
            this.detailDialogVisible.id = id;
            this.detailDialogVisible.status = 4;
            this.detailDialogVisible.show = true
        },
        changeDetail(val) {
            this.detailDialogVisible.id = null
            this.detailDialogVisible.show = val
            this.detailDialogVisible.status = 0;
        },
        getRefundStatus() {
            refundApi.getRefundStatus().then(resp => {
                this.refundStatusList = resp.data;
            })
        },
        getAccountList() {
            accountApi.list().then(resp => {
                this.adviserList = resp.data
            })
        },
        exportRefund() {
            const loading = this.$loading({
                lock: true,
                text: '玩命加载中...请稍后...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            if (this.refundDateArr) {
                this.queryParams.refundStartDate = this.refundDateArr[0];
                this.queryParams.refundEndDate = this.refundDateArr[1];
            } else {
                this.queryParams.refundStartDate = null;
                this.queryParams.refundEndDate = null;
            }
            if (this.commitDateArr) {
                this.queryParams.commitStartDate = this.commitDateArr[0];
                this.queryParams.commitEndDate = this.commitDateArr[1];
            } else {
                this.queryParams.commitStartDate = null;
                this.queryParams.commitEndDate = null;
            }
            refundApi.exportRefund(this.queryParams).then(res => {
                const blob = new Blob([res]);
                const down_link = document.createElement('a')
                down_link.download = '退款申请' + new Date().getTime() + '.xlsx'
                down_link.style.display = 'none';
                down_link.href = URL.createObjectURL(blob);
                document.body.appendChild(down_link);
                down_link.click();
                URL.revokeObjectURL(down_link.href); // 释放URL 对象
                document.body.removeChild(down_link);
                loading.close();
            })
        },
        queryLog(id) {
            refundApi.queryLog(id).then(res => {
                if (res.success) {
                    this.logList = res.data
                    this.refundLogDialogVisible = true
                }
            })
        },
    },
    mounted() {
        this.getRefundStatus();
        this.getAccountList();
        this.handleQuery();
        this.isShowBack = this.$route.query.isShowBack
    }
}


</script>

<style scoped>

</style>
